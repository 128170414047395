<template>
  <div class="questionnaire">
    <div class="content">
      <div class="title-box" @click="$router.back()">
        <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
        <img src="../../../assets/images/learn/wenjuan-title.png" alt="">
      </div>
      <question :themeColor="themeColor" :buttomText="buttomText" :imgUrl="imgUrl" :type="type" :typeId="typeId">
      </question>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      themeColor: '#1972FF',
      buttomText: '提交问卷',
      type: this.$route.query.type,
      typeId: this.$route.query.typeId,
      imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/c-h5-fe/appFormHeader.png',
    }
  }
}
</script>

<style lang="scss" scoped>
.questionnaire {
	background-color: #f4f7fa;
	min-height: calc(100vh - 80px);
}
.content {
	width: 1200px;
	margin: 0 auto;
	padding-top: 57px;
}
.title-box {
	display: flex;
	align-items: center;
	position: relative;
	left: -40px;
	margin-bottom: 24px;
	.icon {
		font-size: 32px;
		margin-right: 8px;
		cursor: pointer;
	}
	img {
		width: 116px;
	}
}

</style>